import React from "react"
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./authConfig"
import { Toaster } from "react-hot-toast"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"

// ===== Redux Provider =====
import { Provider } from "react-redux"

// ===== MSAL Provider =====
import { MsalProvider } from "@azure/msal-react"

// ===== Redux Store =====
import { store } from "./store/store"

// ===== Template Main CSS File =====
import "./assets/css/style.css"

const msalInstance = new PublicClientApplication(msalConfig)
await msalInstance.initialize()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
      <Toaster position="top-right" reverseOrder={false} gutter={8} />
    </MsalProvider>
  </Provider>
)