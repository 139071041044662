import React, { useEffect, useState } from "react"
import { changeAccessToken, changeAuthenticated, changeUserName, changeUserEmail } from "./store/slices/MSALSlice"
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { changeCurrentUserValue } from "./store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentUser } from "./services/ApiServices"
import { loginRequest } from "./authConfig"
import { scope } from "./const"
import Layout from "./components/Layout/Layout"
import Loader from "./components/Loader/Loader"
import moment from "moment"
import "./App.css"

export default function App() {
  const CurrentUser = useSelector((state) => state.APIResponse.CurrentUser)
  const accessToken = useSelector((state) => state.MSALAuth.accessToken)

  const [isLoading, setIsLoading] = useState(false)
  const [intervalTimeForAccessToken, setIntervalTimeForAccessToken] = useState(3600000)

  const { instance, inProgress, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const dispatch = useDispatch()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  const setAccessTokenData = (accessTokenResponse) => {
    // Acquire token silent success
    const accessToken = accessTokenResponse.accessToken

    localStorage.setItem("token", accessToken)
    window.accessToken = accessToken
    window.expToken = accessTokenResponse.idTokenClaims.exp

    dispatch(changeAccessToken(accessToken))

    setIntervalTimeForAccessToken((moment(accessTokenResponse?.expiresOn).diff(moment()._d, "s") + 1) * 1000)
  }

  const handleResponse = (response) => {
    if (response) {
      setAccessTokenData(response)
    } else if (accounts && accounts?.length === 0) {
      handleLogin()
    } else if (accounts && accounts?.length === 1) {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    } else if (accounts && accounts.length > 1) {
      // Add your account choosing logic here
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }
  }

  // Get Current User Data
  useEffect(() => {
    let subscribed = true

    if (accessToken) {
      setIsLoading(true)
      getCurrentUser().then((response) => {
        subscribed && dispatch(changeCurrentUserValue(response))
      }).catch((error) => {
        subscribed && console.warn(error)
      }).finally(() => {
        subscribed && setIsLoading(false)
      })
    }
    return () => { subscribed = false }
  }, [accessToken])

  useEffect(() => {
    const interval = setInterval(() => {
      const accessTokenRequest = { scopes: [scope], account: accounts[0] }

      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          setAccessTokenData(accessTokenResponse)
        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          console.warn(error)
        })
      }
    }, intervalTimeForAccessToken)

    return () => { clearInterval(interval) }
  }, [intervalTimeForAccessToken])

  useEffect(() => {
    dispatch(changeAuthenticated(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      dispatch(changeUserName(accounts[0] ? accounts[0]?.name : ""))
      dispatch(changeUserEmail(accounts[0] ? accounts[0]?.username : ""))
    }
  }, [accounts])

  useEffect(() => {
    instance.handleRedirectPromise().then(handleResponse).catch((error) => console.warn(error))
  }, [isAuthenticated, inProgress])

  return (
    <AuthenticatedTemplate>
      {!isLoading && Object.keys(CurrentUser)?.length > 0
        ? <Layout />
        : <Loader />
      }
    </AuthenticatedTemplate>
  )
}