import React, { useEffect, useMemo, useState } from "react"
import { getLocations, getSeatsByTenantIdLocationIdAndAreaId, getTenants, switchTenant, updateSeatsByTenantIdLocationIdAndAreaId } from "../../services/ApiServices"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import Loader from "../Loader/Loader"
import axios from "axios"
import "./Body.css"

export default function Body() {
    const CurrentUser = useSelector((state) => state.APIResponse.CurrentUser)
    const dymo = window.dymo

    const [isLoading, setIsLoading] = useState(false)
    const [XMLTemplate, setXMLTemplate] = useState("")

    const localStoragePrinterName = useMemo(() => window.localStorage.getItem("printerName"), [])

    const [printerName, setPrinterName] = useState(localStoragePrinterName ?? "DYMO LabelWriter 550 Turbo")
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [allTenants, setAllTenants] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [tempAllSeats, setTempAllSeats] = useState([])
    const [allSeats, setAllSeats] = useState([])
    const [allSelectedSeats, setAllSelectedSeats] = useState([])

    const [inputTenant, setInputTenant] = useState(0)
    const [inputLocation, setInputLocation] = useState(0)
    const [inputArea, setInputArea] = useState(0)

    const isAllSelected = useMemo(() => {
        let isUnselected = allSeats && allSeats.length > 0 ? allSeats.filter((d) => d?.Selected === false) : []
        if (isUnselected.length > 0 ? true : false) {
            let selectedSeats = allSeats && allSeats.length > 0 ? allSeats.filter((d) => d?.Selected === true) : []
            setAllSelectedSeats(selectedSeats)
        } else {
            setAllSelectedSeats(allSeats)
        }
        return isUnselected.length === 0 ? true : false
    }, [allSeats])

    const allAreas = useMemo(() => (
        allLocations?.find((d) => d?.Id === inputLocation)?.Areas
    ), [allLocations, inputLocation])

    const data = useMemo(() => (
        allSeats?.filter((d, i) => tempAllSeats[i]?.Name?.trim() !== d?.Name?.trim())
    ), [allSeats, tempAllSeats])

    const handleAllSelect = () => {
        if (isAllSelected) {
            setAllSeats((prev) => prev.map((d) => { return { ...d, Selected: false } }))
        } else {
            setAllSeats((prev) => prev.map((d) => { return { ...d, Selected: true } }))
        }
    }

    const handleChange = (index, value, key) => {
        let tempAllSeats = [...allSeats]
        tempAllSeats[index] = { ...tempAllSeats[index], [`${key}`]: value }
        setAllSeats(tempAllSeats)
    }

    const replaceString = (Id, Name) => {
        let newString = XMLTemplate

        if (newString) {
            newString = newString?.replace("{{Seat Name}}", `${Name}`)
            newString = newString?.replace("{{Seat Id}}", `${Id}`)
            newString = newString?.replace("{{Seat Id}}", `${Id}`)
        }

        return newString
    }

    const PrintAllLabels = async (printerName) => {
        if (XMLTemplate && printerName) {
            // Check If Printer Is Connected
            setIsLoading(true)
            setIsModalOpen(false)
            window.localStorage.setItem("printerName", printerName)
            const connectedPrinters = await dymo.label.framework.getPrinters()
            if (connectedPrinters?.byIndex?.length > 0) {
                // Print All Seats
                for (let i = 0; i < allSelectedSeats?.length; i++) {
                    await dymo.label.framework.printLabel(printerName, "", replaceString(allSelectedSeats[i]?.Id, allSelectedSeats[i]?.Name), "")
                }
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error("Printer Not Connected!")
            }
        } else {
            if (!XMLTemplate) {
                toast.error("Template not found!")
            } else if (!printerName) {
                toast.error("Please provide printer name!")
            }
        }
    }

    const GetSeatsByTenantIdLocationIdAndAreaId = async (tenantId, locationId, areaId) => {
        if (tenantId && locationId && areaId) {
            setIsLoading(true)
            await getSeatsByTenantIdLocationIdAndAreaId(inputTenant, inputLocation, inputArea).then((response) => {
                setTempAllSeats(response && response.length > 0 ? response : [])
                setAllSeats(response && response.length > 0 ? response.map((d) => ({ ...d, Selected: true })) : [])
            }).catch((error) => {
                setTempAllSeats([])
                setAllSeats([])
                toast.error(error)
            }).finally(() => {
                setIsLoading(false)
            })
        } else {
            setTempAllSeats([])
            setAllSeats([])
        }

        return
    }

    const UpdateSeatsByTenantIdLocationIdAndAreaId = async (tenantId, locationId, areaId) => {
        if (tenantId && locationId && areaId) {
            if (data?.length > 0) {
                setIsLoading(true)
                await updateSeatsByTenantIdLocationIdAndAreaId(tenantId, locationId, areaId, data?.map((d) => ({ Id: d?.Id, Name: d?.Name }))).then(async () => {
                    await GetSeatsByTenantIdLocationIdAndAreaId(tenantId, locationId, areaId)
                    toast.success("Record Updated Successfully.")
                }).catch((error) => {
                    toast.error(error)
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        }
    }

    // Get All Seats
    useEffect(() => {
        GetSeatsByTenantIdLocationIdAndAreaId(inputTenant, inputLocation, inputArea)
    }, [inputTenant, inputLocation, inputArea])

    // Switch Tenant And Get Locations
    useEffect(() => {
        let subscribed = true

        if (inputTenant) {
            setIsLoading(true)
            setInputArea(0)
            setInputLocation(0)
            setAllLocations([])
            switchTenant(inputTenant).then(async (response) => {
                if (subscribed) {
                    if (!response) {
                        await getLocations().then((response) => {
                            setAllLocations(response && response?.length > 0 ? response : [])
                        }).catch((error) => {
                            setAllLocations([])
                            toast.error(error)
                        })
                    }
                }
            }).catch((error) => {
                setAllLocations([])
                toast.error("Error occurred! ", error)
            }).finally(() => {
                setIsLoading(false)
            })
        }

        return () => { subscribed = false }
    }, [inputTenant])

    // Get All Tenants
    useEffect(() => {
        setIsLoading(true)
        getTenants().then((response) => {
            setAllTenants(response && response?.length > 0 ? response : [])
            response && response?.length > 0 && setInputTenant(response.find((d) => d?.IsCurrent)?.TenantId)
        }).catch((error) => {
            setAllTenants([])
            toast.error(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    // Getting XML file from assets folder
    useEffect(() => {
        axios.get("./assets/XMLTemplates/XMLTemplate.xml", { "Content-Type": "application/xml charset=utf-8" }).then((response) => {
            setXMLTemplate(response?.data ? response?.data : "")
        }).catch((error) => {
            setXMLTemplate("")
            console.warn(error);
        })
    }, [])

    return (
        <>
            {isLoading && <Loader />}

            <main id="main" className="main">
                <div className="card mb-0 p-4">
                    {(CurrentUser?.IsAppAdmin || CurrentUser?.Roles?.includes("Administrator")) ? (
                        <>
                            <div className="row">
                                {/* ==== Input Tenant ==== */}
                                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="col-4 col-md-12 col-lg-12">
                                            <label className="mb-2" htmlFor="Tenant">Tenant</label>
                                        </div>
                                        <div className="col-8 col-md-12 col-lg-12">
                                            <select value={inputTenant} onChange={(e) => setInputTenant(Number(e.target.value))} className="form-select">
                                                <option value={0}>Select Tenant</option>
                                                {allTenants?.length > 0 &&
                                                    allTenants?.map((tenant, tenantIndex) =>
                                                        <option key={tenantIndex} value={tenant?.TenantId}>
                                                            {tenant?.TenantName}
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* ==== Input Location ==== */}
                                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="col-4 col-md-12 col-lg-12">
                                            <label className="mb-2" htmlFor="Location">Location</label>
                                        </div>
                                        <div className="col-8 col-md-12 col-lg-12">
                                            <select value={inputLocation} onChange={(e) => setInputLocation(Number(e.target.value))} className="form-select">
                                                <option value={0}>Select Location</option>
                                                {allLocations?.length > 0 &&
                                                    allLocations?.map((location, locationIndex) =>
                                                        <option key={locationIndex} value={location?.Id}>
                                                            {location?.Name ? location?.Name : "--"}
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* ==== Input Area ==== */}
                                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="col-4 col-md-12 col-lg-12">
                                            <label className="mb-2" htmlFor="Area">Area</label>
                                        </div>
                                        <div className="col-8 col-md-12 col-lg-12">
                                            <select value={inputArea} onChange={(e) => setInputArea(Number(e.target.value))} className="form-select">
                                                <option value={0}>Select Area</option>
                                                {allAreas?.length > 0 &&
                                                    allAreas?.map((area, areaIndex) =>
                                                        <option key={areaIndex} value={area?.Id}>
                                                            {area?.Name ? area?.Name : "--"}
                                                        </option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between w-100 mb-3">
                                <button onClick={() => handleAllSelect()} disabled={!allSeats?.length} className="btn btn-primary px-3 py-2 me-2">
                                    {allSeats?.length > 0 && isAllSelected ? "Deselect All" : "Select All"}
                                </button>
                                <div className="">
                                    <button
                                        type="button"
                                        onClick={() => setIsModalOpen(true)} disabled={!allSelectedSeats?.length}
                                        data-bs-toggle="modal" data-bs-target="#printModal"
                                        className="btn btn-primary px-3 py-2 me-2"
                                    >
                                        Print &#40;{allSelectedSeats?.length}&#41;
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => UpdateSeatsByTenantIdLocationIdAndAreaId(inputTenant, inputLocation, inputArea)}
                                        disabled={!data?.length}
                                        className="btn btn-primary px-3 py-2"
                                    >
                                        Save &#40;{data?.length}&#41;
                                    </button>
                                </div>
                            </div>

                            <div className="print-table">
                                <div className="table-row table-header">
                                    <div className="table-col-1 px-3 py-2 text-center">#</div>
                                    <div className="table-col-9 px-3 py-2">Seat Name</div>
                                </div>
                                {allSeats?.length > 0
                                    ? allSeats?.map((d, i) =>
                                        <div key={i} className="table-row">
                                            <div className="table-col-1 px-3 py-2 text-center">
                                                <input type="checkbox" checked={d?.Selected} onChange={() => handleChange(i, !d?.Selected, "Selected")} className="checkbox-input align-bottom" />
                                            </div>
                                            <div className="table-col-9 px-3 py-2">
                                                <input value={d?.Name} onChange={(e) => handleChange(i, e.target.value, "Name")} className="form-control" placeholder="Enter Seat" />
                                            </div>
                                        </div>
                                    )
                                    : <div className="table-row">
                                        <div className="col-12 text-center">No data in this table!</div>
                                    </div>
                                }
                            </div>
                        </>
                    ) : (
                        <h3 className="text-center mt-4">
                            You Are Unauthorized!
                        </h3>
                    )}
                </div>

                {/* Modal */}
                <div className={`modal fade${isModalOpen ? " show d-block" : ""}`} id="printModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h5 className="modal-title">Modal title</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsModalOpen(false)
                                        setPrinterName(window.localStorage.getItem("printerName") ?? "DYMO LabelWriter 550 Turbo")
                                    }}
                                    className="btn close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div> */}
                            <div className="modal-body">
                                <label className="mb-2">Printer Name</label>
                                <input
                                    type="text" value={printerName}
                                    onChange={(e) => setPrinterName(e.target.value)}
                                    className="form-control" placeholder="Printer name"
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsModalOpen(false)
                                        setPrinterName(window.localStorage.getItem("printerName") ?? "DYMO LabelWriter 550 Turbo")
                                    }}
                                    className="btn btn-secondary" data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="button" onClick={() => PrintAllLabels(printerName)} className="btn btn-primary" style={{ backgroundColor: "#1a327d",border:"1px solid #1a327d" }}>
                                    Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
