import axios from "axios"
import { base_url } from "../const"

const axiosConnectorInstance = axios.create({
  baseURL: base_url,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
});

export default axiosConnectorInstance