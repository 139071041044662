import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CurrentUser: {},
};

export const APIResponseSlice = createSlice({
  name: "API",
  initialState,
  reducers: {
    changeCurrentUserValue: (state, action) => {
      state.CurrentUser = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCurrentUserValue } = APIResponseSlice.actions;

export default APIResponseSlice.reducer;
