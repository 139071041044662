import axiosConnectorInstance from "./AxiosConnector"
import { router } from "../utils/APIUrls"

export const getCurrentUser = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.currentUser, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getUserImage = async (userEmail) => {
  try {
    const res = await axiosConnectorInstance.get(`${router.userImage}?userId=${userEmail}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getTenants = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.tenants, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const switchTenant = async (tenantId) => {
  try {
    const res = await axiosConnectorInstance.put(`${router.switchTenant}/${tenantId}`, "", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getLocations = async () => {
  try {
    const res = await axiosConnectorInstance.get(router.locations, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const getSeatsByTenantIdLocationIdAndAreaId = async (tenantId, locationId, areaId) => {
  try {
    const res = await axiosConnectorInstance.get(`${router.adminTenant}/${tenantId}/Locations/${locationId}/Areas/${areaId}/Seats`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}

export const updateSeatsByTenantIdLocationIdAndAreaId = async (tenantId, locationId, areaId, queryData) => {
  try {
    const res = await axiosConnectorInstance.put(`${router.adminTenant}/${tenantId}/Locations/${locationId}/Areas/${areaId}/Seats`,
      queryData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
    return error.response.data
  }
}