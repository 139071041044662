import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  accessToken: "",
  userName: "",
  userEmail: "",
  userProfile: "",
};

export const MSALSlice = createSlice({
  name: "MSAL",
  initialState,
  reducers: {
    changeAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    changeAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    changeUserName: (state, action) => {
      state.userName = action.payload;
    },
    changeUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    changeUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeAuthenticated, changeAccessToken, changeUserName, changeUserEmail, changeUserProfile } = MSALSlice.actions;

export default MSALSlice.reducer;