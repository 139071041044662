import React from "react"
import { BarLoader } from "react-spinners"
import { useSelector } from "react-redux"
import "./Loader.css"

import myDeskLogo from "../../assets/img/mydesk-logo.png"

export default function Loader() {
    const CurrentUser = useSelector((state) => state.APIResponse.CurrentUser)
    return (
        <div className="position-fixed d-flex align-items-center justify-content-center loader">
            <div className="loader-groupe">
                <img src={CurrentUser?.AppLogo && CurrentUser?.AppLogo?.Image ? CurrentUser?.AppLogo?.Image : myDeskLogo} alt="logo" className="mb-2" />
                <BarLoader loading={true} size={150} color="#fff" />
            </div>
        </div>
    )
}
