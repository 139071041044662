import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMsal } from "@azure/msal-react"
import { changeAuthenticated, changeUserProfile } from "../../store/slices/MSALSlice"
import { getUserImage } from "../../services/ApiServices"
import "./Header.css"

import userThumb from "../../assets/img/user_thumb.jpg"

export default function Header() {
    const userName = useSelector((state) => state.MSALAuth.userName)
    const userEmail = useSelector((state) => state.MSALAuth.userEmail)
    const userProfile = useSelector((state) => state.MSALAuth.userProfile)
    const CurrentUser = useSelector((state) => state.APIResponse.CurrentUser)

    // Theming States
    const [appLogo, setAppLogo] = useState("")
    const [AppThemeColor, setAppThemeColor] = useState("#1A327D")

    const { instance } = useMsal()
    const dispatch = useDispatch()

    function handleLogout() {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" })
        dispatch(changeAuthenticated(false))
    }


    useEffect(() => {
        setAppLogo(CurrentUser && CurrentUser?.AppLogo && CurrentUser?.AppLogo?.Image ? CurrentUser?.AppLogo?.Image : "")
        setAppThemeColor(CurrentUser && CurrentUser?.Theme && CurrentUser?.Theme?.AppThemeColor ? "#" + CurrentUser?.Theme?.AppThemeColor : "#1A327D")
    }, [CurrentUser])

    useEffect(() => {
        getUserImage(userEmail).then((response) => dispatch(changeUserProfile(response?.Image)))
    }, [])

    return (
        <header className="header fixed-top d-flex align-items-center" style={{ backgroundColor: AppThemeColor }}>
            <div className="d-flex align-items-center justify-content-between">
                <a href="/" className="logo d-flex align-items-center">
                    {appLogo && <img src={appLogo} alt="MyDesk" />}
                </a>
            </div>
            <nav className="header-nav ms-auto dropdown" data-bs-toggle="dropdown">
                <ul className="d-flex align-items-center dropdown-toggle">
                    <li className="nav-item dropdown">
                        <div className="nav-link nav-profile d-flex align-items-center pe-0">
                            <span className="d-none d-md-block pe-2">Hi, <span>{userName}</span> </span>
                            <img src={userProfile ? userProfile : userThumb} alt="Profile" className="rounded-circle" />
                        </div>
                    </li>
                </ul>
                <ul className="dropdown-menu dropdown-menu-arrow position-absolute profile overflow-hidden">
                    <li className="dropdown-header">
                        <h6 style={{ color: AppThemeColor }}>{userName}</h6>
                        <span>{userEmail}</span>
                    </li>
                    <li><hr className="dropdown-divider" style={{ color: AppThemeColor }} /></li>
                    <li>
                        <div onClick={() => handleLogout()} className="admin-drop-down dropdown-item d-flex align-items-center">
                            <i className="fa-solid fa-arrow-right-from-bracket" style={{ color: AppThemeColor }} />
                            <span>Sign Out</span>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    )
}
